import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authService';
import Kreedlogo from '../assets/kreedlogo.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await login(username, password);
            if (response.token) {
                toast.success('Login successfull', {
                    onClose: () => navigate('/dashboard')
                });
            }
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        toast.error('You are not an admin');
                        break;
                    case 400:
                        toast.error('Invalid credentials');
                        break;
                    default:
                        toast.error('Something went wrong. Please try again later.');
                        break;
                }
            } else {
                toast.error('Network error. Please try again later.');
            }
            console.error('Login failed', error);
        }
    };

    return (
        <div className="block bg-light-100 xl:grid grid-cols-2">
            <div className="hidden xl:flex bg-green-100 flex-col justify-center items-center min-h-screen rounded-tr-[155px] rounded-br-[155px]">
                <div className="scale-[4]">
                    <img className="h-12 w-auto" src={Kreedlogo} alt="" />
                </div>
                {/* <p>Your Sporting Destination</p> */}
            </div>
            <div className="flex items-center bg-light-100 justify-center min-h-screen">
                <form onSubmit={handleSubmit} className="w-2/4">
                    <div className="mb-4">
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder='email'
                            className="intro-x border-2 border-gray-300 form-control w-full rounded-md py-3 px-4 outline-none block"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="intro-x border-2 border-gray-300 form-control w-full rounded-md py-3 px-4 outline-none block"
                            placeholder='password'
                            required
                        />
                    </div>
                    <button type="submit" className="w-full bg-customGreen hover:bg-customGreen text-white py-2 rounded hover:bg-blue-600">
                        Login
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Login;
