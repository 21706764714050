import axios from 'axios';
import { baseUrl } from '../Api/BaseUrl';

const login = async (username, password) => {
  const response = await axios.post(`${baseUrl}/academy/login`, { email : username, password : password });
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
    console.log(response.data)
    localStorage.setItem('acedemyId', response.data.data.id);
    
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem('token');
};

const getToken = () => {
  return localStorage.getItem('token');
};

export { login, logout, getToken };
