import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Carousel from './postCorosal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HiMiniFire } from "react-icons/hi2";
import { FaRegCommentAlt } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdLocationOn } from "react-icons/md";

const AllPosts = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visibleScores, setVisibleScores] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const observer = useRef();

  useEffect(() => {
    fetchPosts();
    observeLastPost();
  }, [page]);

  // useEffect(() => {
  //   // Reset posts and page number when a post is deleted
  //   if (postToDelete) {
  //     setPosts([]);
  //     setPage(1);
  //     fetchPosts();
  //   }
  // }, []);

  const fetchPosts = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await axios.get(`https://kreeddev.azurewebsites.net/posts/getAllPosts/:userId?page=${page}&limit=4`);
      const { data } = response.data;
      setPosts(prevPosts => [...prevPosts, ...data]);
      setHasMore(data.length > 0);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      await axios.delete(`https://kreeddev.azurewebsites.net/posts/deletePostById/${postId}`);
      toast.success('Post Deleted successfully', {
        onClose: () => {
          setShowModal(false);
          setPostToDelete(null);
          setPosts([]);
          setPage(1);
          fetchPosts();
        }
      });
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const observeLastPost = () => {
    observer.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    const lastPostElement = document.querySelector('.last-post');
    if (lastPostElement) {
      observer.current.observe(lastPostElement);
    }
  };

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const truncateName = (name) => {
    return name.length > 8 ? name.substring(0, 8) + '...' : name;
  };

  const handleShowMoreScores = (postId) => {
    setVisibleScores(prevState => ({
      ...prevState,
      [postId]: true
    }));
  };

  function timeAgo(timestamp) {
    const seconds = Math.floor((Date.now() - new Date(timestamp).getTime()) / 1000);

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let intervalType;
    let intervalValue;

    for (const key in intervals) {
      //@ts-ignore
      intervalValue = Math.floor(seconds / intervals[key]);
      if (intervalValue >= 1) {
        intervalType = key;
        break;
      }
    }

    //@ts-ignore
    if (intervalValue > 1 || intervalValue === 0) {
      intervalType += 's';
    }

    return `${intervalValue} ${intervalType} ago`;
  }

  return (
    <div>
      <h1 className="text-2xl mb-4">All Posts</h1>
      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="w-full max-w-3xl">

          <div className='flex gap-4'>
            <InfiniteScroll
              dataLength={posts.length}
              next={() => setPage(page + 1)}
              hasMore={hasMore}
              loader={<p>Loading...</p>}
              endMessage={<p>No more posts</p>}
            >
              {posts.map((post, index) => (

                <div key={post.id} className={`border p-4 mb-4 w-full shadow-md rounded-lg ${index === posts.length - 1 ? 'last-post' : ''}`}>
                  <div className='flex justify-between'>
                    <div className="flex items-center mb-2">
                      <img src={post.user.profile} alt={post.user.fname} className="w-10 h-10 rounded-full mr-2" />
                      <div>
                        <h2 className="text-lg font-semibold">{post.user.fname}</h2>
                        <p className="text-gray-500">{timeAgo(post.createdAt)}</p>
                      </div>
                    </div>
                    <div className='items-center mb-2 text-2xl font-bold cursor-pointer' onClick={() => { setShowModal(true); setPostToDelete(post.id); }}>
                      ...
                    </div>
                  </div>
                  {post.location && post.location.address && (
                    <p className="p-2 flex items-center">
                      <MdLocationOn className="text-blue-500 mr-1" />
                      <span className="text-sm">{post.location.address}</span>
                    </p>
                  )}
                  <p className='p-2'>{post.caption}</p>

                  {post.GameIds?.slice(0, visibleScores[post.id] ? post.GameIds.length : 2).map((game) => (
                    <div key={game.id} className="mb-4 p-4 shadow-md rounded-lg">
                      <div className="flex">
                        <div className="w-1/2 p-2 flex items-center">
                          <div className='w-3/5'>
                            {game?.teams[0]?.players?.map(player => (
                              <div key={player.id} className="flex items-center mb-2">
                                <img src={player.user.profile} alt={player.user.fname} className="w-8 h-8 rounded-full mr-2" />
                                <span className="truncate" title={player.user.fname}>
                                  {truncateName(player.user.fname)}
                                </span>
                              </div>
                            )) || <p>No players available</p>}
                          </div>
                          <div className="flex justify-center items-center w-2/5 mb-2">
                            <p className="font-semibold text-center">{game?.teams[0]?.scores[0]?.score || 'N/A'}</p>
                          </div>
                        </div>
                        <div className="flex justify-center items-center mb-2">
                          <h1 className='text-lg font-bold text-center'>VS</h1>
                        </div>
                        <div className="w-1/2 p-2 flex items-center">
                          <div className="flex justify-center items-center w-2/5 mb-2">
                            <p className="font-semibold text-center">{game?.teams[1]?.scores[0]?.score || 'N/A'}</p>
                          </div>
                          <div className='w-3/5'>
                            {game?.teams[1]?.players?.map(player => (
                              <div key={player.id} className="flex items-center mb-2">
                                <img src={player.user.profile} alt={player.user.fname} className="w-8 h-8 rounded-full mr-2" />
                                <span className="truncate" title={player.user.fname}>
                                  {truncateName(player.user.fname)}
                                </span>
                              </div>
                            )) || <p>No players available</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}


                  {post.GameIds.length > 2 && !visibleScores[post.id] && (
                    <div className="text-right">
                      <button
                        onClick={() => handleShowMoreScores(post.id)}
                        className="bg-white-500 text-black py-2 px-4 rounded-lg"
                      >
                        See More....
                      </button>
                    </div>
                  )}

                  <Carousel items={post.imageUrls} />

                  <div className="mt-4 flex gap-6">
                    <p className="text-gray-600 flex items-center px-4 py-2 shadow-md rounded-lg">
                      <HiMiniFire className="w-6 h-6 text-orange-500 mr-4" />
                      <span>{post.like.length}</span>
                    </p>
                    <p className="text-gray-600 flex items-center px-4 py-2 shadow-md rounded-lg">
                      <FaRegCommentAlt className="w-6 h-6 text-gray-600 mr-4" />
                      <span>{post._count.comments}</span>
                    </p>
                  </div>
                </div>
              ))}
              {loading && <p>Loading...</p>}
            </InfiniteScroll>
          </div>
          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Are you sure do you want to delete this post?</h2>
                <div className="flex justify-end">
                  <button
                    onClick={() => setShowModal(false)}
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg mr-4"
                  >
                    No
                  </button>
                  <button
                    onClick={() => handleDeletePost(postToDelete)}
                    className="bg-red-500 text-white py-2 px-4 rounded-lg"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      </div>
    </div>

  );
};

export default AllPosts;
