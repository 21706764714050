import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCircleInfo } from "react-icons/fa6";

const Badminton = () => {
  const [users, setUsers] = useState([]);
  const [users1, setUsers1] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const limit = 10;

  useEffect(() => {
    fetchUsers(page, status, searchTerm);
  }, [page, status, searchTerm]);

  const acedemyId = localStorage.getItem("acedemyId");

  const fetchUsers = async (currentPage, status, search) => {
    try {
      const response = await axios.get(`https://kreeddev.azurewebsites.net/user/allUsersBadData?page=${currentPage}&limit=${limit}&status=${status}&AcademyId=${acedemyId}&search=${search}`);
      setUsers(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchUsers1 = async (status, search) => {
    try {
      const response = await axios.get(`https://kreeddev.azurewebsites.net/user/allUsersBadData?status=${status}&AcademyId=${acedemyId}&search=${search}`);
      setUsers1(response.data.data);
      downloadCSV(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const truncateName = (name) => {
    return name.length > 12 ? name.substring(0, 12) + '...' : name;
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const downloadCSV = (data) => {
    const csvContent = [
      [
        "SR No",
        "User Name",
        "Total Matches",
        "No. of Matches Win",
        "No. of Matches Loss",
        "No. of Single Played",
        "No. of Single Win",
        "No. of Double Played",
        "No. of Double Win",
        "Single Win %",
        "Double Win %",
        "Total Points Scored"
      ],
      ...data.map((user, index) => {
        const stat = user.stat?.[0] || {};
        return [
          index + 1,
          user.fname,
          stat.totalPlayed || 0,
          stat.totalWon || 0,
          (stat.totalPlayed || 0) - (stat.totalWon || 0),
          stat.singlePlayed || 0,
          stat.singleWon || 0,
          stat.doublePlayed || 0,
          stat.doubleWon || 0,
          stat.singleWonPer || 0,
          stat.doubleWonPer || 0,
          user.totalPoints || 0
        ];
      })
    ];

    const csvString = csvContent
      .map(row => row.map(item => `"${item}"`).join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'badminton_users_data.csv';
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownloadCSV = () => {
    fetchUsers1(status, searchTerm); // Fetch data and download CSV
  };


  return (
    <div>
      <div className='flex justify-between p-2'>
        <h1 className='text-2xl font-bold'>Badminton Stats</h1>
        <div className='flex gap-4'>
          <div className="relative group">
            <FaCircleInfo className="text-customGreen cursor-pointer h-6 w-6 mt-2" />
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 min-w-[300px] px-4 py-2 rounded text-white bg-black text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              Total points: The total points are the addition of the points which scored by player in singles and team points in doubles.
            </div>
          </div>
          <input
            type="text"
            className="px-4 py-2 border rounded hover:border-blue-500"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {/* Uncomment the status filter if needed */}
          <select
            className='px-8 py-2 border rounded hover:border-blue-500'
            value={status}
            onChange={handleStatusChange}
          >
            <option value="">Select</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <button
            onClick={handleDownloadCSV}
            className="px-4 py-2 bg-customGreen hover:bg-customGreen text-white rounded"
          >
            Download CSV
          </button>
        </div>
      </div>
      <div className="overflow-auto max-h-[60vh] px-4">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-200 sticky top-0 z-5">
            <tr>
              <th className="py-2 px-2 border-b">SR No</th>
              <th className="py-2 px-2 border-b">User Name</th>
              <th className="py-2 px-2 border-b">Total Matches</th>
              <th className="py-2 px-2 border-b">No.of Matches Win</th>
              <th className="py-2 px-2 border-b">No.of Matches loss</th>
              <th className="py-2 px-2 border-b">No.of Single Played</th>
              <th className="py-2 px-2 border-b">No.of Single Win</th>
              <th className="py-2 px-2 border-b">No.of Double Played</th>
              <th className="py-2 px-2 border-b">No.of Double Win</th>
              <th className="py-2 px-2 border-b">Single Win %</th>
              <th className="py-2 px-2 border-b">Double Win %</th>
              {/* <th className="py-2 px-2 border-b">Win Loss Ratio</th> */}
              <th className="py-2 px-2 border-b">Total Points Scored</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              const stat = user.stat?.[0] || {};
              return (
                <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-2 border-b text-center">{(page - 1) * limit + index + 1}</td>
                  <td className="py-2 px-2 border-b text-center">
                    <span className="truncate" title={user.fname}>
                      {truncateName(user.fname)}
                    </span>
                  </td>
                  <td className="py-2 px-2 border-b text-center">{stat.totalPlayed || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.totalWon || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.totalPlayed - stat.totalWon || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.singlePlayed || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.singleWon || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.doublePlayed || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.doubleWon || 0}</td>
                  <td className="py-2 px-2 border-b text-center">{stat.singleWonPer || 0} %</td>
                  <td className="py-2 px-2 border-b text-center">{stat.doubleWonPer || 0} %</td>
                  {/* <td className="py-2 px-2 border-b text-center">
                    {stat?.totalPlayed && stat?.totalPlayed - stat?.totalWon ? (stat?.totalWon / (stat?.totalPlayed - stat?.totalWon)).toFixed(2) : 0}
                  </td> */}
                  <td className="py-2 px-2 border-b text-center">{user.totalPoints || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex gap-2 mt-2 p-2">
        <button
          onClick={handlePrev}
          disabled={page === 1}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
        >
          Prev
        </button>
        <span className="px-4 py-2">{page}</span>
        <button
          onClick={handleNext}
          disabled={page === totalPages}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default Badminton