import React from 'react';
//import Select from 'react-select';

const RoomDetailsModal = ({ room, onClose, moments, selectedMoments, handleMomentClick, gameScores }) => {

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg max-h-full overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">{room.roomname} Details</h2>
                <div className="mb-4">
                    <div className="overflow-y-auto h-4/5">
                        <h3 className="text-lg font-semibold">Score Cards</h3>
                        <div className="flex flex-wrap">
                            {gameScores.map((game, index) => (
                                <div key={game.gameId} className="w-1/2 md:w-1/2 lg:w-1/2 p-4">
                                    {game?.scores[0]?.scores[0]?.score >= 0 && (
                                        <div className="shadow-md rounded-lg px-2 py-2">
                                            <div className="flex">
                                                <div className="w-1/2 p-2 flex items-center">
                                                    <div className='w-3/5'>
                                                        {game.scores[0].players.map(player => (
                                                            <div key={player.playerId} className="flex items-center mb-2">
                                                                <img src={player.user.profile} alt={player.user.fname} className="w-8 h-8 rounded-full mr-2" />
                                                                <span className="truncate" title={player.user.fname}>
                                                                    {player.user.fname}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="flex justify-center items-center w-2/5 mb-2">
                                                        <p className="font-semibold text-center">{game?.scores[0]?.scores[0]?.score}</p>
                                                    </div>
                                                </div>
                                                <div className="flex justify-center items-center mb-2">
                                                    <h1 className='text-lg font-bold text-center'>VS</h1>
                                                </div>
                                                <div className="w-1/2 p-2 flex items-center">
                                                    <div className="flex justify-center items-center w-2/5 mb-2">
                                                        <p className="font-semibold text-center">{game?.scores[1]?.scores[0]?.score}</p>
                                                    </div>
                                                    <div className='w-3/5'>
                                                        {game.scores[1].players.map(player => (
                                                            <div key={player.playerId} className="flex items-center mb-2">
                                                                <img src={player.user.profile} alt={player.user.fname} className="w-8 h-8 rounded-full mr-2" />
                                                                <span className="truncate" title={player.user.fname}>
                                                                    {player.user.fname}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {/* <h3 className="text-lg font-semibold">Moments</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {moments.map((moment) => (
                                <div
                                    key={moment.id}
                                    className={`p-5 rounded-lg shadow-lg cursor-pointer ${selectedMoments.some((m) => m.id === moment.id) ? 'bg-blue-100' : 'bg-white'}`}
                                    onClick={() => handleMomentClick(moment)}
                                >
                                    <div className="flex items-center gap-2 mb-3">
                                        <img src={moment.user.profile} alt={moment.user.fname} className="w-10 h-10 rounded-full" />
                                        <p className="text-lg font-semibold">{moment.user.fname}</p>
                                    </div>
                                    {moment.roomMomPic.map((pic) => (
                                        <div key={pic.id} className="mb-3">
                                            {pic.image.endsWith('.mp4') ? (
                                                <video controls className="w-full h-40 rounded-lg">
                                                    <source src={pic.image} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img src={pic.image} alt="Moment" className="w-full h-40 rounded-lg object-cover" />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div> */}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RoomDetailsModal;
