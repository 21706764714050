import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaEdit } from 'react-icons/fa';

const Location = () => {
    const [Location, setLocation] = useState([]);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 10;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [editedAddress, setEditedAddress] = useState('');
    const [editedLatitude, setEditedLatitude] = useState('');
    const [editedLongitude, setEditedLongitude] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file first.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('https://kreeddev.azurewebsites.net/location/bulkUpload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 201) {
                toast.success('Location added successfully !');
                setFile(null)
                fetchLocation(page);
            }

        } catch (error) {
            toast.error('Error uploading file: ' + error.message)
            setMessage('Error uploading file: ' + error.message);
        }
    };

    useEffect(() => {
        fetchLocation(page);
    }, [page]);

    const fetchLocation = async (currentPage) => {
        try {
            const response = await axios.get(`https://kreeddev.azurewebsites.net/location/locations?page=${currentPage}&limit=10`);
            setLocation(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching Location:', error);
        }
    };

    const truncateName = (name) => {
        return name.length > 16 ? name.substring(0, 16) + '...' : name;
    };

    const handleNext = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleDeleteClick = (locationId) => {
        setSelectedLocationId(locationId);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await axios.delete(`https://kreeddev.azurewebsites.net/location/locations/${selectedLocationId}`);
            if (response.status === 200) {
                toast.success('Location deleted successfully!');
                fetchLocation(page);
            }
        } catch (error) {
            console.error('Error deleting Location:', error);
            toast.error('Error deleting Location: ' + error.message);
        }
        setShowDeleteModal(false);
    };

    const downloadCsv = () => {
        const csvContent = "address,latitude,longitude\n";
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "demo.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleEditClick = (location) => {
        setSelectedLocation(location);
        setEditedAddress(location.address);
        setEditedLatitude(location.lattitude);
        setEditedLongitude(location.longitude);
        setIsEditModalOpen(true);
    };

    const handleSaveClick = async () => {
        try {
            const updatedLocation = {
                id: selectedLocation.id,
                address: editedAddress,
                latitude: editedLatitude,
                longitude: editedLongitude,
            };

            const response = await axios.put(`https://kreeddev.azurewebsites.net/location/locations/${selectedLocation.id}`, updatedLocation);
            if (response.status === 200) {
                toast.success('Location updated successfully!');
                fetchLocation(page);
                setIsEditModalOpen(false);
            }
        } catch (error) {
            console.error('Failed to update location', error);
            toast.error('Error deleting Location: ' + error.message);
        }
    };

    return (
        <div>
            <div className="flex ml-4 gap-4">
                <div>
                    <h3 className="text-xl font-bold mb-2 ">Upload CSV File</h3>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        className="mb-2 p-2 mr-4 border border-gray-300 rounded cursor-pointer"
                    />
                    <button
                        onClick={handleUpload}
                        className="bg-blue-500 text-white px-4 py-3 rounded hover:bg-blue-600"
                    >
                        Upload
                    </button>
                    {message && <p className="mt-2 text-red-500">{message}</p>}
                </div>
                <div>
                    <h2 className="text-xl font-bold mb-2 ml-8">Download CSV File</h2>
                    <button
                        onClick={downloadCsv}
                        className="bg-blue-500 text-white ml-8 px-4 py-3 rounded hover:bg-blue-600"
                    >
                        Download File
                    </button>
                </div>
            </div>
            <div className="overflow-auto max-h-[60vh] px-4">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-200 sticky top-0 z-5">
                        <tr>
                            <th className="py-2 px-2 border-b">SR No</th>
                            <th className="py-2 px-2 border-b">Location Name</th>
                            <th className="py-2 px-2 border-b">Latitude</th>
                            <th className="py-2 px-2 border-b">Longitude</th>
                            <th className="py-2 px-2 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Location.length > 0 ? (
                            Location.map((location, index) => (
                                <tr key={location.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                    <td className="py-2 px-4 border-b text-center">{(page - 1) * limit + index + 1}</td>
                                    <td className="py-2 px-4 border-b text-center">{truncateName(location.address)}</td>
                                    <td className="py-2 px-4 border-b text-center">{location.lattitude}</td>
                                    <td className="py-2 px-4 border-b text-center">{location.longitude}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <button onClick={() => handleEditClick(location)}><FaEdit className='text-customGreen mr-2' /></button>
                                        <button onClick={() => handleDeleteClick(location.id)}><FaTrash color="red" /></button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="py-2 px-4 border-b text-center text-red-500">
                                    No Locations to show
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            <div className="flex gap-2 mt-1 p-2">
                <button
                    onClick={handlePrev}
                    disabled={page === 1}
                    className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                >
                    Prev
                </button>
                <span className="px-4 py-2">{page}</span>
                <button
                    onClick={handleNext}
                    disabled={page === totalPages}
                    className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                >
                    Next
                </button>
            </div>
            {showDeleteModal && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <p>Are you sure you want to delete this location?</p>
                        <div className="mt-4 flex justify-end">
                            <button onClick={() => setShowDeleteModal(false)} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded mr-2">No</button>
                            <button onClick={handleDeleteConfirm} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">Yes</button>
                        </div>
                    </div>
                </div>
            )}
            {isEditModalOpen && (
                <EditModal
                    location={selectedLocation}
                    address={editedAddress}
                    latitude={editedLatitude}
                    longitude={editedLongitude}
                    setAddress={setEditedAddress}
                    setLatitude={setEditedLatitude}
                    setLongitude={setEditedLongitude}
                    onClose={() => setIsEditModalOpen(false)}
                    onSave={handleSaveClick}
                />
            )}
            <ToastContainer />
        </div>
    );
};

const EditModal = ({ location, address, latitude, longitude, setAddress, setLatitude, setLongitude, onClose, onSave }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSave();
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-lg">
                <h2 className="text-xl mb-4">Edit Location</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Location Name</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Latitude</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Longitude</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            className="px-4 py-2 bg-gray-300 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Location;
