import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../Api/BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import RoomDetailsModal from './RoomDetailsModal';

const Room = () => {
  const [rooms, setRooms] = useState([]);
  const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);
  const [showAddMomentsModal, setShowAddMomentsModal] = useState(false);
  const [createPost, setCreatePost] = useState(false);
  const [newRoom, setNewRoom] = useState({
    roomname: 'Kreed',
    sportType: '',
  });
  const [roomId, setRoomId] = useState("");

  const [momentDetails, setMomentDetails] = useState({
    roomId: '',
    details: '',
  });

  const [files, setFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);

  const [gamesScores, setGamesScores] = useState([]);
  const [moments, setMoments] = useState([]);
  const [selectedMoments, setSelectedMoments] = useState([]);
  const [caption, setCaption] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;

  useEffect(() => {
    fetchRooms(page);
  }, [page]);

  const acedemyId = localStorage.getItem("acedemyId");

  const fetchRooms = (currentPage) => {
    axios.get(`${baseUrl}/rooms/getAllRooms?AcademyId=${acedemyId}&page=${currentPage}&limit=${limit}`)
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          setRooms(response.data.data);
          setTotalPages(response.data.totalPages);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the rooms!', error);
      });
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleCreateRoom = () => {
    const userId = localStorage.getItem('userId');
    const postData = {
      ...newRoom,
      myUserId: userId,
    };

    axios.post(`${baseUrl}/rooms/createRoom`, postData)
      .then(response => {
        if (response.status === 201) {
          toast.success('Room created successfully!', {
            onClose: () => {
              setShowCreateRoomModal(false);
              fetchRooms();
            }
          });

        } else {
          toast.error('Something went wrong!');
        }
      })
      .catch(error => {
        toast.error('There was an error creating the room!');
        console.error('There was an error creating the room!', error);
      });
  };

  const handleAddMoment = async () => {
    const userId = localStorage.getItem('userId');
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('roomId', roomId);
    //formData.append('details', momentDetails.details);
    //formData.append('duration', momentDetails.duration);

    files.forEach((file) => {
      formData.append('images', file);
    });

    thumbnails.forEach((thumbnail, index) => {
      formData.append(`thumbnail`, thumbnail, `thumbnail-${index}.png`);
    });

    axios.post(`${baseUrl}/moment/moment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        if (response.status === 201) {
          toast.success('Moment added successfully!', {
            onClose: () => {
              setShowAddMomentsModal(false);
            }
          });
        } else {
          toast.error('Something went wrong!');
        }
      })
      .catch(error => {
        toast.error('There was an error adding the moment!');
        console.error('There was an error adding the moment!', error);
      });
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    const thumbnails = await Promise.all(selectedFiles.map(file => captureThumbnail(file)));
    setThumbnails(thumbnails);
  };

  const captureThumbnail = (file) => {
    return new Promise((resolve) => {
      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        video.addEventListener('loadeddata', () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          video.currentTime = video.duration / 2; // Capture at the middle of the video
        });

        video.addEventListener('seeked', () => {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(resolve, 'image/png');
        });

        video.src = URL.createObjectURL(file);
      } else if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            canvas.toBlob(resolve, 'image/png');
          };
          image.src = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const creatorOptions = rooms.map((creator) => ({
    //@ts-ignore
    value: creator.id,
    //@ts-ignore
    label: `${creator.roomname} (${creator.roomcode})`,
  }));
  //console.log(roomId)

  const fetchGameData = async (roomId) => {
    try {
      const gamesResponse = await axios.get(`${baseUrl}/rooms/rooms/games/scores/${roomId}`);
      setGamesScores(gamesResponse.data.data);
    } catch (error) {
      console.error('Error fetching game data:', error);
    }
  };

  const fetchMomentsData = async (roomId) => {
    try {
      const momentsResponse = await axios.get(`https://kreeddev.azurewebsites.net/moment/moments/${roomId}`);
      setMoments(momentsResponse.data.data);
    } catch (error) {
      console.error('Error fetching moments data:', error);
    }
  };

  const handleMomentClick = (moment) => {
    setSelectedMoments((prevSelected) => {
      if (prevSelected.some((m) => m.id === moment.id)) {
        return prevSelected.filter((m) => m.id !== moment.id);
      } else {
        return [...prevSelected, moment];
      }
    });
  };

  const handleRoomSelect = async (selectedOption) => {
    setRoomId(selectedOption.value);
    //await fetchGameData(selectedOption.value);
    await fetchMomentsData(selectedOption.value);
  };

  const handleCreatePost = async () => {
    if (selectedMoments.length === 0) return;

    const imageUrls = selectedMoments.flatMap(moment =>
      moment.roomMomPic.map(pic => ({
        images: pic.image,
        thumbnail: pic.thumbnail || pic.image,
        momentCreater: moment.userId,
      }))
    );

    const userId = localStorage.getItem('userId');
    const postData = {
      userId: userId,
      caption: caption,
      //tagline: "new post",
      imageUrls,
    };

    try {
      const response = await axios.post('https://kreeddev.azurewebsites.net/posts/posts', postData);
      console.log('Post created successfully:', response.data);
      if (response.status === 201) {
        toast.success('Post created successfully!', {
          onClose: () => {
            setCreatePost(false);
          }
        });
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error('Error creating post:', error);
      toast.error('Something went wrong!');
    }
  };

  console.log(selectedMoments)
  return (
    <div className="p-2">
      <h1 className="text-2xl font-bold mb-2">Room List</h1>
      {/* <div className="mb-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          onClick={() => setShowCreateRoomModal(true)}
        >
          Create Room
        </button>
        <button
          className="bg-customGreen text-white px-4 py-2 rounded mr-2"
          onClick={() => setShowAddMomentsModal(true)}
        >
          Add Moments
        </button>
        <button
          className="bg-orange-500 text-white px-4 py-2 rounded"
          onClick={() => setCreatePost(true)}
        >
          Create Post
        </button>
      </div> */}
      <div className="overflow-auto max-h-[55vh]">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-200 sticky top-0 z-5">
            <tr>
              <th className="py-2 px-4 border-b">SR No</th>
              <th className="py-2 px-4 border-b">Room Name</th>
              <th className="py-2 px-4 border-b">Room Code</th>
              <th className="py-2 px-4 border-b">Sport Type</th>
              <th className="py-2 px-4 border-b">Players moment</th>
              {/* <th className="py-2 px-4 border-b">Room Status</th> */}
            </tr>
          </thead>
          <tbody>
            {rooms.map((room, index) => (
              <tr
                key={room.id}
                className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} cursor-pointer hover:bg-customGreen hover:text-white`}
                onClick={() => {
                  setSelectedRoom(room);
                  fetchMomentsData(room.id);
                  fetchGameData(room.id);
                }}
              >
                <td className="py-2 px-4 border-b text-center">{(page - 1) * limit + index + 1}</td>
                <td className="py-2 px-4 border-b text-center">{room.roomname}</td>
                <td className="py-2 px-4 border-b text-center">{room.roomcode}</td>
                <td className="py-2 px-4 border-b text-center">{room.sportType}</td>
                <td className="py-2 px-4 border-b text-center">{room._count.moments}</td>
                {/* <td className="py-2 px-4 border-b text-center">{room.isGameEnded ? 'Ended' : 'Not Ended'}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex gap-4 mt-2 px-4">
        <button
          onClick={handlePrev}
          disabled={page === 1}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200"
        >
          Prev
        </button>
        <span className="px-4 py-2">{page}</span>
        <button
          onClick={handleNext}
          disabled={page === totalPages}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200"
        >
          Next
        </button>
      </div>

      {showCreateRoomModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Create Room</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleCreateRoom(); }}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="roomname">Room Name</label>
                <input
                  id="roomname"
                  type="text"
                  value={newRoom.roomname}
                  onChange={(e) => setNewRoom({ ...newRoom, roomname: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="sportType">Sport Type</label>
                <select
                  id="sportType"
                  value={newRoom.sportType}
                  onChange={(e) => setNewRoom({ ...newRoom, sportType: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                >
                  <option value="">Select</option>
                  <option value="Cricket">Cricket</option>
                  <option value="Badminton">Badminton</option>
                </select>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={() => setShowCreateRoomModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showAddMomentsModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Add Moments</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleAddMoment(); }}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="roomId">Choose Room</label>
                <Select
                  options={creatorOptions}
                  onChange={(selectedOption) =>
                    //@ts-ignore
                    setRoomId(selectedOption.value)}
                  isSearchable
                  className="basic-single border-2 border-black p-2"
                  classNamePrefix="select"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="details">Details</label>
                <textarea
                  id="details"
                  value={momentDetails.details}
                  onChange={(e) => setMomentDetails({ ...momentDetails, details: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="files">Files (Images/Videos)</label>
                <input
                  id="files"
                  type="file"
                  multiple
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={() => setShowAddMomentsModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add Moment
                </button>
              </div>
            </form>
          </div>
        </div >
      )}

      {createPost && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg ">
            <h2 className="text-xl font-bold mb-4">Create Post</h2>
            <form onSubmit={(e) => { e.preventDefault(); }}>
              {/* Room Selector */}
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="roomId">Choose Room</label>
                <Select
                  options={creatorOptions}
                  onChange={handleRoomSelect}
                  isSearchable
                  className="basic-single border-2 border-black p-2"
                  classNamePrefix="select"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="caption">Caption</label>
                <input
                  type="text"
                  id="caption"
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
              </div>
              {/* Display Games Scores */}

              {/* <div>
                <h3 className="text-lg font-bold mb-2">Games Scores</h3>
                {gamesScores?.map((game, index) => (
                  <div key={index} className='flex gap-2'>
                    {game.scores.map((score, scoreIndex) => (
                      <div key={scoreIndex} className='flex gap-2'>
                        <div >
                          {score.players.map((player, playerIndex) => (
                              <div key={playerIndex} className='flex gap-2'>
                                <img src={player.user.profile} alt={player.user.fname} className='w-6 h-4' />
                                <p>{player.user.fname}</p>
                              </div>
                          ))}
                        </div>
                        <p>Score: {score.scores[0].score}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div> */}

              {/* Display Moments */}
              <div className="overflow-y-auto h-80">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {moments.map((moment) => (
                    <div
                      key={moment.id}
                      className={`p-5 rounded-lg shadow-lg cursor-pointer ${selectedMoments.some((m) => m.id === moment.id) ? 'bg-blue-100' : 'bg-white'}`}
                      onClick={() => handleMomentClick(moment)}
                    >
                      <div className="flex items-center gap-2 mb-3">
                        <img src={moment.user.profile} alt={moment.user.fname} className="w-10 h-10 rounded-full" />
                        <p className="text-lg font-semibold">{moment.user.fname}</p>
                      </div>
                      {moment.roomMomPic.map((pic) => (
                        <div key={pic.id} className="mb-3">
                          {pic.image.endsWith('.mp4') ? (
                            <video controls className="w-full h-40 rounded-lg">
                              <source src={pic.image} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={pic.image} alt="Moment" className="w-full h-40 rounded-lg object-cover" />
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              {/* Form actions */}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mt-5 px-4 py-2 bg-gray-500 text-white  rounded-lg mr-4"
                  onClick={() => setCreatePost(false)}
                >
                  Cancel
                </button>
                <button
                  className="mt-5 px-4 py-2 bg-red-500 text-white rounded-lg"
                  onClick={handleCreatePost}
                >
                  Publish
                </button>
              </div>
            </form>
          </div>
        </div >
      )}

      {selectedRoom && (
        <RoomDetailsModal
          room={selectedRoom}
          onClose={() => setSelectedRoom(null)}
          moments={moments}
          gameScores={gamesScores}
          selectedMoments={selectedMoments}
          handleMomentClick={handleMomentClick}
        />
      )}

      <ToastContainer />
    </div >
  );
};

export default Room;

