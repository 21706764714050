import React, { useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import DashboardHome from './DashContents/DashboardHome';
import AllUsers from './DashContents/AllUsers';
import AllPosts from './DashContents/AllPosts';
import Cricket from './DashContents/Cricket';
import Badminton from './DashContents/Badminton';
import Room from './DashContents/Room';
import Location from './DashContents/Location';
import Academy from './DashContents/Academy';

const Dashboard = () => {
  const [selectedPage, setSelectedPage] = useState('home');

  const handlePageSelect = (page) => {
    setSelectedPage(page);
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <div className="flex mt-4">
        <div className="w-1/5 ">
          <Sidebar handlePageSelect={handlePageSelect} selectedPage={selectedPage} />
        </div>
        <div className="w-4/5 px-1 bg-customWhite">
          {selectedPage === 'home' && <DashboardHome handlePageSelect={handlePageSelect} />}
          {selectedPage === 'allusers' && <AllUsers />}
          {selectedPage === 'allposts' && <AllPosts />}
          {selectedPage === 'cricket' && <Cricket />}
          {selectedPage === 'badminton' && <Badminton />}
          {selectedPage === 'rooms' && <Room />}
          {selectedPage === 'location' && <Location />}
          {selectedPage === 'academy' && <Academy />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
