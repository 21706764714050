import React, { useEffect, useState } from 'react';

const RoundGraph = ({ activeUsers, inactiveUsers }) => {
  const [successRate, setSuccessRate] = useState(0);
  const [failRate, setFailRate] = useState(0);
  const totalUsers = activeUsers + inactiveUsers;

  useEffect(() => {
    if (totalUsers > 0) {
      const successPercentage = (activeUsers / totalUsers) * 100;
      const failPercentage = (inactiveUsers / totalUsers) * 100;
      setSuccessRate(successPercentage);
      setFailRate(failPercentage);
    }
  }, [totalUsers, activeUsers, inactiveUsers]);

  const radius = 80;
  const circumference = 2 * Math.PI * radius;
  const successStroke = (successRate / 100) * circumference;
  const failStroke = (failRate / 100) * circumference;

  return (
    <svg width="100" height="100" viewBox="0 0 200 200">
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke="#ccc"
        strokeWidth="20"
      />
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke="#80D053"
        strokeWidth="20"
        strokeDasharray={`${successStroke} ${circumference}`}
        transform="rotate(-90 100 100)"
      />
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        stroke="#f44336"
        strokeWidth="20"
        strokeDasharray={`${failStroke} ${circumference}`}
        transform={`rotate(${successStroke / circumference * 360 - 90} 100 100)`}
      />
    </svg>
  );
};

export default RoundGraph;
