import React from 'react';
//import { CiGrid41 } from "react-icons/ci";
import Badicon from "../assets/badminton.jpg";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiUsersThree } from "react-icons/pi";
import { CiImageOn } from "react-icons/ci";
import { MdMeetingRoom } from "react-icons/md";
//import { IoLocationOutline } from "react-icons/io5";
//import { MdSportsScore } from "react-icons/md";

const Sidebar = ({ handlePageSelect, selectedPage }) => {

  return (
    <div className="flex flex-col h-full bg-whilte-200">
      <div className="px-6">
        <div className={`flex items-center mb-1 p-4 cursor-pointer ${selectedPage === 'home' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('home')}>
          <LuLayoutDashboard className="ml-4 w-6 h-6 mr-4" />
          Dashboard
        </div>
      </div>
      <div className="px-6">
        <div className={`flex items-center mb-1 p-4 cursor-pointer ${selectedPage === 'allusers' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('allusers')}>
          <PiUsersThree className="ml-4 w-6 h-6 mr-4" />
          All Players
        </div>
      </div>
      <div className="px-6">
        <div className={`flex items-center mb-1 p-4 cursor-pointer ${selectedPage === 'allposts' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('allposts')}>
          <CiImageOn className="ml-4 w-6 h-6 mr-4" />
          All Posts
        </div>
      </div>
      {/* <div className="px-6">
        <div className={`flex items-center mb-1 p-4 cursor-pointer ${selectedPage === 'cricket' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('cricket')}>
          <CiGrid41 className="ml-4 w-6 h-6 mr-4" />
          Cricket
        </div>
      </div> */}
      <div className="px-6">
        <div className={`flex items-center mb-1 p-4 cursor-pointer ${selectedPage === 'badminton' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('badminton')}>
          <img src={Badicon} alt='badminton' className="ml-4 w-6 h-6 mr-4 bg-customGreen" />
          Badminton
        </div>
      </div>
      <div className="px-6">
        <div className={`flex items-center mb-1 p-4 cursor-pointer ${selectedPage === 'rooms' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('rooms')}>
          <MdMeetingRoom className="ml-4 w-6 h-6 mr-4" />
          Room
        </div>
      </div>
      {/* <div className="px-6">
        <div className={`flex items-center p-4 cursor-pointer ${selectedPage === 'location' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('location')}>
          <IoLocationOutline className="ml-4 w-6 h-6 mr-4" />
          Location
        </div>
      </div>
      <div className="px-6">
        <div className={`flex items-center p-4 cursor-pointer ${selectedPage === 'academy' ? 'bg-customGreen text-white' : ''}`} onClick={() => handlePageSelect('academy')}>
          <MdSportsScore className="ml-4 w-6 h-6 mr-4" />
          Academy
        </div>
      </div> */}

    </div>
  );
};

export default Sidebar;
