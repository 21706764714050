import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PlayerModal = ({ isOpen, onClose, bestPerformer }) => {
    const [gameScores, setGameScores] = useState([]);
    const [filteredGameScores, setFilteredGameScores] = useState([]);

    useEffect(() => {
        if (isOpen && bestPerformer?.id) {
            axios.get(`https://kreeddev.azurewebsites.net/history/getGameHistory/${bestPerformer.id}?page=1&limit=500`)
                .then(response => {
                    const data = response.data.data;
                    setGameScores(data);
                    // Filter data and update filteredGameScores
                    const filteredData = data.filter(game => game.sportType === "Badminton" && game.scores.length > 0);
                    setFilteredGameScores(filteredData);
                })
                .catch(error => {
                    console.error("Error fetching game history:", error);
                });
        }
    }, [isOpen, bestPerformer]);

    // Early return if modal is not open
    if (!isOpen) return null;

    // Debugging: Check if onClose is working
    console.log("Rendering PlayerModal", filteredGameScores);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-3/5 p-2 rounded relative max-h-[90vh] overflow-y-auto">
                <button className="absolute top-2 right-2 text-red-500 hover:text-red-700" onClick={onClose}>✖</button>
                <h2 className="text-lg font-bold">Player Details</h2>
                <div className='flex gap-2'>
                    <div className='w-1/4'>
                        <img
                            src={bestPerformer?.profile || 'https://images.pexels.com/photos/90946/pexels-photo-90946.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
                            alt="Profile"
                            className="w-24 h-24 rounded-full mx-auto mb-4"
                        />
                    </div>
                    <div className='w-3/4'>
                        <p className='text-lg font-bold'>Name: {bestPerformer?.fname}</p>
                        <p className='text-md mb-2'>Age: {bestPerformer?.age}</p>
                        <p className='text-md mb-2'>Gender: {bestPerformer?.gender}</p>
                        <p className='text-md mb-2'>Total Points: {bestPerformer?.totalPoints}</p>
                    </div>
                </div>

                <h3 className="text-lg font-bold">Scorecards:</h3>
                <div className="max-h-50 overflow-y-auto">
                    <div className="flex flex-wrap">
                        {filteredGameScores.map(game => (
                            <div key={game.id} className="w-1/2 md:w-1/2 lg:w-1/2 p-4">
                                {game?.scores[0]?.score >= 0 && (
                                    <div className="shadow-md rounded-lg px-2 py-2">
                                        <div className="flex">
                                            {/* Team 1 */}
                                            <div className="w-1/2 p-2 flex items-center">
                                                <div className='w-3/5'>
                                                    {game.teams[0].players.map(player => (
                                                        <div key={player.id} className="flex items-center mb-2">
                                                            <img src={player.user.profile} alt={player.user.fname} className="w-8 h-8 rounded-full mr-2" />
                                                            <span className="truncate" title={player.user.fname}>
                                                                {player.user.fname}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="flex justify-center items-center w-2/5 mb-2">
                                                    <p className="font-semibold text-center">{game.scores.find(score => score.teamId === game.teams[0].id)?.score || 0}</p>
                                                </div>
                                            </div>
                                            
                                            {/* VS */}
                                            <div className="flex justify-center items-center mb-2">
                                                <h1 className='text-lg font-bold text-center'>VS</h1>
                                            </div>
                                            
                                            {/* Team 2 */}
                                            <div className="w-1/2 p-2 flex items-center">
                                                <div className="flex justify-center items-center w-2/5 mb-2">
                                                    <p className="font-semibold text-center">{game.scores.find(score => score.teamId === game.teams[1].id)?.score || 0}</p>
                                                </div>
                                                <div className='w-3/5'>
                                                    {game.teams[1].players.map(player => (
                                                        <div key={player.id} className="flex items-center mb-2">
                                                            <img src={player.user.profile} alt={player.user.fname} className="w-8 h-8 rounded-full mr-2" />
                                                            <span className="truncate" title={player.user.fname}>
                                                                {player.user.fname}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerModal;
