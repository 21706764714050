import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const UserEngagedModal = ({ isOpen, onClose }) => {
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [status] = useState("active"); // Default status is active
    const acedemyId = localStorage.getItem("acedemyId");

    const fetchUsers = async (page, status) => {
        try {
            const response = await axios.get(`https://kreeddev.azurewebsites.net/user/users?page=${page}&limit=10&status=${status}&AcademyId=${acedemyId}`);
            setUsers(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchUsers(currentPage, status);
        }
    }, [isOpen, currentPage, status]);

    const fetchAllUsers = async () => {
        try {
            const response = await axios.get(`https://kreeddev.azurewebsites.net/user/users?status=${status}&AcademyId=${acedemyId}`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching all users:', error);
            return [];
        }
    };

    const exportCSV = async () => {
        const allUsers = await fetchAllUsers();
        console.log(allUsers.length, allUsers);

        const csvConfig = mkConfig({
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useBom: true,
            useKeysAsHeaders: true,
            filename: 'inactiveUsers.csv'
        });

        const data = allUsers.map((user, index) => ({
            srNo: index + 1,
            name: user.fname,
            email: user.email,
            status: user.status === "active" ? 'Active' : 'Inactive',
            joinDate: formatDate(user.createdAt),
            rooms: user.userRoom.length,
            roomEnded: user.roomEnded,
            games: user.gameCount,
            scoreCards: user.totalScore,
        }));

        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const truncateName = (name) => {
        return name.length > 20 ? `${name.substring(0, 17)}...` : name;
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 shadow-lg rounded-md bg-white">
                <button onClick={onClose} className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded">Close</button>
                <h2 className="text-2xl font-bold mb-4">Engaged Users</h2>
                <div className="overflow-auto max-h-[60vh] px-4">
                    <button onClick={exportCSV} className="bg-blue-500 text-white px-4 py-2 mb-4 rounded">Export CSV</button>
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-200 sticky top-0 z-5">
                            <tr>
                                <th className="py-2 px-4 border-b">SR No</th>
                                <th className="py-2 px-4 border-b">Name</th>
                                <th className="py-2 px-4 border-b">Email</th>
                                <th className="py-2 px-4 border-b">Phone no.</th>
                                <th className="py-2 px-4 border-b">Gender</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Join Date</th>
                                {/* <th className="py-2 px-4 border-b">Total Matches</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                    <td className="py-2 px-4 border-b text-center">{(currentPage - 1) * 10 + index + 1}</td>
                                    <td className="py-2 px-4 border-b text-center">{user.fname}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <span className="truncate" title={user.email}>
                                            {truncateName(user.email)}
                                        </span>
                                    </td>
                                    <td className="py-2 px-4 border-b text-center">{user.phonenumber}</td>
                                    <td className="py-2 px-4 border-b text-center">{user.gender === "male" ? "Male" : user.gender === "female" ? "Female" : ""}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        {user.status === "active" ? <span className="text-green-500">Active</span> : <span className="text-red-500">Inactive</span>}
                                    </td>
                                    <td className="py-2 px-4 border-b text-center">{formatDate(user.createdAt)}</td>
                                    {/* <td className="py-2 px-4 border-b text-center">{user.gameCount}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserEngagedModal;
