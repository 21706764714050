import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash, FaEdit, FaPlus } from 'react-icons/fa';
import Select from 'react-select';

const Academy = () => {
    const [academies, setAcademies] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 10;
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAcademyId, setSelectedAcademyId] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedAcademy, setSelectedAcademy] = useState(null);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedOwnerName, setEditedOwnerName] = useState('');
    const [editedPhoneNumber, setEditedPhoneNumber] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const [newName, setNewName] = useState('');
    const [newOwnerName, setNewOwnerName] = useState('');
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        fetchAcademies(page);
        fetchLocations();
    }, [page]);

    const fetchAcademies = async (currentPage) => {
        try {
            const response = await axios.get(`https://kreeddev.azurewebsites.net/academy/?page=${currentPage}&limit=10`);
            setAcademies(response.data.academies);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching academies:', error);
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`https://kreeddev.azurewebsites.net/location/locations`);
            const locationOptions = response.data.data.map(location => ({
                value: location.id,
                label: location.address
            }));
            setLocations(locationOptions);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const truncateName = (name) => {
        if (!name) return 'N/A';
        return name.length > 16 ? name.substring(0, 16) + '...' : name;
    };

    const handleNext = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleDeleteClick = (academyId) => {
        setSelectedAcademyId(academyId);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await axios.delete(`https://kreeddev.azurewebsites.net/academy/${selectedAcademyId}`);
            if (response.status === 200) {
                toast.success('Academy deleted successfully!');
                fetchAcademies(page);
            }
        } catch (error) {
            console.error('Error deleting academy:', error);
            toast.error('Error deleting academy: ' + error.message);
        }
        setShowDeleteModal(false);
    };

    const handleEditClick = (academy) => {
        setSelectedAcademy(academy);
        setSelectedLocation({ value: academy.location.id, label: academy.location.address });
        setEditedName(academy.nameOfAcademy);
        setEditedOwnerName(academy.ownerName);
        setEditedPhoneNumber(academy.phonenumber);
        setEditedEmail(academy.email);
        setIsEditModalOpen(true);
    };

    const handleAddClick = () => {
        setIsAddModalOpen(true);
    };

    const handleSaveClick = async () => {
        try {
            const updatedAcademy = {
                id: selectedAcademy.id,
                nameOfAcademy: editedName,
                ownerName: editedOwnerName,
                phonenumber: editedPhoneNumber,
                email: editedEmail
            };
            
            if (selectedLocation) {
                updatedAcademy.locationId = selectedLocation.value;
            }

            const response = await axios.put(`https://kreeddev.azurewebsites.net/academy/${selectedAcademy.id}`, updatedAcademy);
            if (response.status === 200) {
                toast.success('Academy updated successfully!');
                fetchAcademies(page);
                setIsEditModalOpen(false);
            }
        } catch (error) {
            console.error('Failed to update academy', error);
            toast.error('Error updating academy: ' + error.message);
        }
    };

    const handleAddAcademy = async () => {
        try {
            const newAcademy = {
                nameOfAcademy: newName,
                ownerName: newOwnerName,
                phonenumber: newPhoneNumber,
                email: newEmail,
                password: newPassword
            };

            if (selectedLocation) {
                newAcademy.locationId = selectedLocation.value;
            }

            const response = await axios.post(`https://kreeddev.azurewebsites.net/academy`, newAcademy);
            if (response.status === 201) {
                toast.success('Academy added successfully!');
                fetchAcademies(page);
                setIsAddModalOpen(false);
                setNewName('');
                setNewOwnerName('');
                setNewPhoneNumber('');
                setNewEmail('');
                setSelectedLocation(null);
            }
        } catch (error) {
            console.error('Failed to add academy', error);
            toast.error('Error adding academy: ' + error.message);
        }
    };

    return (
        <div>
            <div className="flex ml-4 gap-4 mb-2">
                <div>
                    <h3 className="text-xl font-bold mb-2">All Academies</h3>
                </div>
                <div>
                    <button onClick={handleAddClick} className="px-4 py-2 bg-blue-500 text-white rounded">
                        Add Academy
                    </button>
                </div>
            </div>
            <div className="overflow-auto max-h-[65vh] px-4">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-200 sticky top-0 z-5">
                        <tr>
                            <th className="py-2 px-2 border-b">SR No</th>
                            <th className="py-2 px-2 border-b">Name of Academy</th>
                            <th className="py-2 px-2 border-b">Owner name</th>
                            <th className="py-2 px-2 border-b">Location</th>
                            <th className="py-2 px-2 border-b">Phone No.</th>
                            <th className="py-2 px-2 border-b">Email</th>
                            <th className="py-2 px-2 border-b">Photo</th>
                            <th className="py-2 px-2 border-b">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {academies.length > 0 ? (
                            academies.map((academy, index) => (
                                <tr key={academy.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                    <td className="py-2 px-4 border-b text-center">{(page - 1) * limit + index + 1}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <span className="truncate" title={academy.nameOfAcademy}>
                                            {truncateName(academy.nameOfAcademy)}
                                        </span>
                                    </td>
                                    <td className="py-2 px-4 border-b text-center">{academy.ownerName}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <span className="truncate" title={academy.location && academy.location.address ? academy.location.address : 'N/A'}>
                                            {academy.location && academy.location.address ? truncateName(academy.location.address) : 'N/A'}
                                        </span>
                                    </td>
                                    <td className="py-2 px-4 border-b text-center">{academy.phonenumber}</td>
                                    <td className="py-2 px-4 border-b text-center">{academy.email}</td>
                                    <td className="py-2 px-4 border-b text-center">{academy.profile ? <img src={academy.profile} alt="Profile" className="w-10 h-10 rounded-full mx-auto" /> : 'N/A'}</td>
                                    <td className="py-2 px-4 border-b text-center">
                                        <button onClick={() => handleEditClick(academy)}><FaEdit className='text-customGreen mr-2' /></button>
                                        <button onClick={() => handleDeleteClick(academy.id)}><FaTrash color="red" /></button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="py-2 px-4 border-b text-center text-red-500">
                                    No Academies to show
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="flex gap-2 mt-1 p-2">
                <button
                    onClick={handlePrev}
                    disabled={page === 1}
                    className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                >
                    Prev
                </button>
                <span className="px-4 py-2">{page}</span>
                <button
                    onClick={handleNext}
                    disabled={page === totalPages}
                    className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                >
                    Next
                </button>
            </div>
            {showDeleteModal && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex justify-center items-center">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <p>Are you sure you want to delete this academy?</p>
                        <div className="mt-4 flex justify-end">
                            <button onClick={() => setShowDeleteModal(false)} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded mr-2">No</button>
                            <button onClick={handleDeleteConfirm} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">Yes</button>
                        </div>
                    </div>
                </div>
            )}

            {isEditModalOpen && (
                <EditModal
                    academy={selectedAcademy}
                    name={editedName}
                    ownerName={editedOwnerName}
                    phoneNumber={editedPhoneNumber}
                    email={editedEmail}
                    locations={locations}
                    selectedLocation={selectedLocation}
                    setName={setEditedName}
                    setOwnerName={setEditedOwnerName}
                    setPhoneNumber={setEditedPhoneNumber}
                    setEmail={setEditedEmail}
                    setSelectedLocation={setSelectedLocation}
                    onClose={() => setIsEditModalOpen(false)}
                    onSave={handleSaveClick}
                />
            )}

            {isAddModalOpen && (
                <AddModal
                    newName={newName}
                    newOwnerName={newOwnerName}
                    newPhoneNumber={newPhoneNumber}
                    newEmail={newEmail}
                    newPassword={newPassword}
                    locations={locations}
                    selectedLocation={selectedLocation}
                    setNewName={setNewName}
                    setNewOwnerName={setNewOwnerName}
                    setNewPhoneNumber={setNewPhoneNumber}
                    setNewEmail={setNewEmail}
                    setNewPassword={setNewPassword}
                    setSelectedLocation={setSelectedLocation}
                    onClose={() => setIsAddModalOpen(false)}
                    onAdd={handleAddAcademy}
                />
            )}

            <ToastContainer />
        </div>
    );
};

const EditModal = ({ academy, name, ownerName, phoneNumber, email, locations, selectedLocation, setName, setOwnerName, setPhoneNumber, setEmail, setSelectedLocation, onClose, onSave }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSave();
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-lg">
                <h2 className="text-xl mb-4">Edit Academy</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Academy Name</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Owner Name</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Phone Number</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Location</label>
                        <Select
                            value={selectedLocation}
                            onChange={setSelectedLocation}
                            options={locations}
                            isSearchable
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            className="px-4 py-2 bg-gray-300 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const AddModal = ({ newName, newOwnerName, newPhoneNumber, newEmail, newPassword, locations, selectedLocation, setNewName, setNewOwnerName, setNewPhoneNumber, setNewEmail, setNewPassword, setSelectedLocation, onClose, onAdd }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onAdd();
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 rounded shadow-lg">
                <h2 className="text-xl mb-4">Add New Academy</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Academy Name</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Owner Name</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={newOwnerName}
                            onChange={(e) => setNewOwnerName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Phone Number</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={newPhoneNumber}
                            onChange={(e) => setNewPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Password</label>
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Location</label>
                        <Select
                            value={selectedLocation}
                            onChange={setSelectedLocation}
                            options={locations}
                            isSearchable
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            className="px-4 py-2 bg-gray-300 rounded"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Add Academy
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Academy;
