import React, { useState } from 'react';
// import { FaRegBell } from 'react-icons/fa';
// import { IoSettingsOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import Kreedlogo from '../assets/kreedlogo.png'
import { logout } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from './Modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const userId = localStorage.getItem('userId');

  const handleUserIconClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleProfileUpdate = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('profileImage', selectedFile);

      try {
        await axios.put(`https://kreeddev.azurewebsites.net/user/update/${userId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        toast.success('Profile picture updated successfully', {
          onClose: () => {
            setModalOpen(false);
            setDropdownOpen(false);
          }
        });
      } catch (error) {
        console.error('Error updating profile picture:', error);
        toast.error('Failed to update profile picture');
      }
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <>
      <div className="h-24 bg-white-500 flex items-center justify-between px-6">
        <div className="text-white text-xl">
          <img
            className="h-16 w-20 ml-10"
            src={Kreedlogo}
            alt='Kreedlogo'
          />
        </div>
        <div className="flex space-x-16">
          {/* <FaRegBell className="h-6 w-6 text-black" />
          <IoSettingsOutline className="h-6 w-6 text-black" /> */}
          <FiUser
            className="h-6 w-6 text-black cursor-pointer"
            onClick={handleUserIconClick}
          />
          {dropdownOpen && (
            <div className="absolute right-0 mt-8 w-48 bg-white border border-gray-200 rounded shadow-lg">
              <button
                className="block w-full px-4 text-right text-black hover:bg-gray-100"
                onClick={() => setDropdownOpen(false)}
              >
                <FaTimes className="inline-block text-red-500" />
              </button>
              <button
                className="block w-full px-4  text-left text-black hover:bg-gray-100"
                onClick={() => setModalOpen(true)}
              >
                Update Profile
              </button>
              <button
                className="block w-full px-4 py-2 text-left text-black hover:bg-gray-100"
                onClick={handleLogout}
              >
                Logout
              </button>
              
            </div>
          )}

        </div>
      </div>
      <div className="h-1 bg-gray-200"></div>
      <ToastContainer />
      <Modal show={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="p-4">
          <h2 className="text-xl mb-4">Update Profile Picture</h2>
          <input
            type="file"
            onChange={handleFileChange}
            className="mb-4"
          />
          <div className="flex justify-end">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              onClick={handleProfileUpdate}
            >
              Update
            </button>
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
