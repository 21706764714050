import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import RoundGraph from './Graph';
import axios from 'axios';
import { baseUrl } from '../../Api/BaseUrl';
import UsersModal from './UserModal';
import UsersInactiveModal from './InactiveModal';
import UserEngagedModal from './userEngagedModal';
import PlayerModal from './PlayerDetailsModal';

const DashboardHome = ({ handlePageSelect }) => {

  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  //const [dummyUsers, setDummyUsers] = useState(0)
  //const [badStat, setBadStat] = useState(0);
  //const [cricStat, setCricStat] = useState(0);
  //const [EngagedUsers, setEngagedUsers] = useState(0);
  const [singleGames, setSingleGames] = useState(0);
  const [doubleGames, setDoubleGames] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInactiveModalOpen, setIsInactiveModalOpen] = useState(false);
  const [isEngagedModalOpen, setIsEngagedModalOpen] = useState(false);
  const [malePlayers, setMalePlayers] = useState(0);
  const [femalePlayers, setFemalePlayers] = useState(0);
  const [bestPerformer, setBestPerformer] = useState(null);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);

  useEffect(() => {
    fetchUserData();
    fetchEngagedUsers();
    fetchBestPerformer();;
  }, []);

  const acedemyId = localStorage.getItem("acedemyId");

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/users?AcademyId=${acedemyId}`);
      const data = response.data;

      setTotalUsers(data.totalUsers);
      setActiveUsers(data.activeUsers);
      setInactiveUsers(data.inactiveUsers);
      //setBadStat(data.totalstats);
      //setCricStat(data.totalcricstats);
      //setDummyUsers(data.dummyUsers);
      let maleCount = 0;
      let femaleCount = 0;

      data.data.forEach(user => {
        if (user.gender === 'male') {
          maleCount++;
        } else if (user.gender === 'female') {
          femaleCount++;
        }
      });

      setMalePlayers(maleCount);
      setFemalePlayers(femaleCount);

    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchBestPerformer = async () => {
    try {
      const response = await axios.get(`https://kreeddev.azurewebsites.net/user/allUsersBadData?AcademyId=${acedemyId}`);
      const users = response.data.data;

      let topPerformer = null;

      users.forEach(user => {
        if (!topPerformer || user.totalScore > topPerformer.totalScore || (user.totalScore === topPerformer.totalScore && user.winCount > topPerformer.winCount)) {
          topPerformer = user;
        }
      });

      setBestPerformer(topPerformer);
    } catch (err) {
      console.log(err.message);
    }
  };

  const fetchEngagedUsers = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/usersEngagementAca?AcademyId=${acedemyId}`);
      const data = response.data;
      setDoubleGames(data.doubleGames);
      setSingleGames(data.singleGames);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <div className="flex justify-between h-250px mt-4">
        <div className="w-1/5 bg-white mb-2 p-6">
          <p className='text-xl mb-6'>All Players</p>
          <h3 className='text-xl font-bold mb-6'>{totalUsers}</h3>
          {/* <p>+3 more than last week</p> */}
        </div>
        <div className="w-2/5 flex gap-2 p-6">
          <div className='bg-gray-100 py-4 px-4 cursor-pointer' onClick={() => setIsModalOpen(true)}>
            <p className='text-xl mb-6'>Active Players</p>
            <h3 className='text-xl font-bold text-customGreen'>{activeUsers}</h3>
          </div>
          <UsersModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
          <div className='bg-gray-100 py-4 px-4 cursor-pointer' onClick={() => setIsInactiveModalOpen(true)}>
            <p className='text-xl mb-6'>Inactive Players</p>
            <h3 className='text-xl font-bold text-red-500'>{inactiveUsers}</h3>
          </div>
          <UsersInactiveModal isOpen={isInactiveModalOpen} onClose={() => setIsInactiveModalOpen(false)} />
          <div className='bg-gray-100 py-4 px-4 cursor-pointer' onClick={() => setIsEngagedModalOpen(true)}>
            <p className='text-xl mb-6'>Engaged Players</p>
            <h3 className='text-xl font-bold text-customGreen'>{activeUsers}</h3>
          </div>
          <UserEngagedModal isOpen={isEngagedModalOpen} onClose={() => setIsEngagedModalOpen(false)} />
        </div>
        <div className="w-2/5 bg-gray-100 p-2 ml-10">
          <div className="flex gap-2 h-3/5">
            <div className='w-2/5 bg-white flex justify-center'>
              <RoundGraph activeUsers={activeUsers} inactiveUsers={inactiveUsers} />
            </div>
            <div>
              <div className="flex items-center mt-4">
                <MdCheckBoxOutlineBlank className="bg-customGreen text-customGreen" />
                <p className="text-lg ml-2">Active Players</p>
              </div>
              <div className="flex items-center">
                <MdCheckBoxOutlineBlank className="bg-red-500 text-red-500" />
                <p className="text-lg ml-2">Inactive Players</p>
              </div>
            </div>
          </div>
          <div>
            <p className='text-xl mt-4 p-2 bg-white text-center cursor-pointer' onClick={() => handlePageSelect('allusers')}>View List of all users</p>
          </div>
        </div>
      </div>
      <div className="flex h-250px gap-8 p-4">
        <div className="w-1/2 p-4 bg-white cursor-pointer"
          onClick={() => setIsPlayerModalOpen(true)}>
          <p className='text-xl mb-4'>Best Player of Academy</p>
          <p className='text-xl font-bold mb-2'>Name : {bestPerformer?.fname}</p>
          <p className='text-xl mb-2'>Total Points : {bestPerformer?.totalPoints}</p>
        </div>
        <div className="w-1/2 p-4 bg-white">
          <p className='text-xl mb-4'>Double Matches</p>
          <h3 className='text-xl font-bold mb-2'>{doubleGames}</h3>
          {/* <p>+0 more than last week</p> */}
        </div>
        <div className="w-1/2 p-4 bg-white">
          <p className='text-xl mb-4'>Single Matches</p>
          <h3 className='text-xl font-bold mb-2'>{singleGames}</h3>
          {/* <p>+3 more than last week</p> */}
        </div>
      </div>
      <div className="flex h-250px gap-8 p-4">
        <div className="w-1/2 p-4 bg-white">
          <p className='text-xl mb-4'>Male Players</p>
          <h3 className='text-xl font-bold mb-2'>{malePlayers}</h3>
        </div>
        <div className="w-1/2 p-4 bg-white">
          <p className='text-xl mb-4'>Female Players</p>
          <h3 className='text-xl font-bold mb-2'>{femalePlayers}</h3>
        </div>
      </div>
      <PlayerModal
        isOpen={isPlayerModalOpen} 
        onClose={() => setIsPlayerModalOpen(false)} 
        bestPerformer={bestPerformer} 
      />
    </div>
  );
};

export default DashboardHome;
