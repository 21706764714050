import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="relative w-full h-full">
      {items.length > 0 && (
        <div className="relative w-full h-1/2 ">
          {items[currentIndex].url.endsWith('.mp4') ? (
            <video key={items[currentIndex].id} controls poster={items[currentIndex].thumbnail} className="w-full h-80 object-cover shadow-md rounded-lg">
              <source src={items[currentIndex].url} type="video/mp4" className='rounded-lg' />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img key={items[currentIndex].id} src={items[currentIndex].url} alt="" className="w-full h-80 object-cover rounded-lg" />
          )}
          <button className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white rounded-full p-2" onClick={handlePrev}>
            <FaArrowLeft />
          </button>
          <button className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white rounded-full p-2" onClick={handleNext}>
            <FaArrowRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
