import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const limit = 10;

  useEffect(() => {
    fetchUsers(page, status, searchTerm);
  }, [page, status, searchTerm]);

  const acedemyId = localStorage.getItem("acedemyId");

  const fetchUsers = async (currentPage, status, search) => {
    try {
      const response = await axios.get(`https://kreeddev.azurewebsites.net/user/users?page=${currentPage}&limit=10&status=${status}&AcademyId=${acedemyId}&search=${search}`);
      setUsers(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const truncateName = (name) => {
    return name.length > 8 ? name.substring(0, 8) + '...' : name;
  };

  return (
    <div>
      <div className='flex justify-between p-4'>
        <h1 className='text-2xl font-bold'>All Players</h1>
        <div className='flex gap-4'>
          <input
            type="text"
            className="px-4 py-2 border rounded hover:border-blue-500"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <select
            className='px-8 py-2 border rounded hover:border-blue-500'
            value={status}
            onChange={handleStatusChange}
          >
            <option value="">select</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
      <div className="overflow-auto max-h-[60vh] px-4">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-200 sticky top-0 z-5">
            <tr>
              <th className="py-2 px-4 border-b">SR No</th>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Phone no.</th>
              <th className="py-2 px-4 border-b">Gender</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Player Type</th>
              <th className="py-2 px-4 border-b">Join Date</th>
              <th className="py-2 px-4 border-b">Total Matches</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              const stat = user.stat?.[0] || {};
              return (
                <tr key={user.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-4 border-b text-center">{(page - 1) * limit + index + 1}</td>
                  <td className="py-2 px-4 border-b text-center">{user.fname}</td>
                  <td className="py-2 px-4 border-b text-center">
                    <span className="truncate" title={user.email}>
                      {truncateName(user.email)}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-center">{user.phonenumber}</td>
                  <td className="py-2 px-4 border-b text-center">{user.gender === "male" ? "Male" : user.gender === "female" ? "Female" : ""}</td>
                  <td className="py-2 px-4 border-b text-center">
                    {user.status === "active" ? <span className="text-green-500">Active</span> : <span className="text-red-500">Inactive</span>}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {user.addPlayingBadminton === "Intermediate" ? "Intermediate" : user.addPlayingBadminton === "Advance" ? "Advance" : user.addPlayingBadminton === "Expert" ? "Expert" : "Beginner"}
                  </td>
                  <td className="py-2 px-4 border-b text-center">{formatDate(user.createdAt)}</td>
                  <td className="py-2 px-4 border-b text-center">{stat.totalPlayed || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex gap-4 mt-4 p-4">
        <button
          onClick={handlePrev}
          disabled={page === 1}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
        >
          Prev
        </button>
        <span className="px-4 py-2">{page}</span>
        <button
          onClick={handleNext}
          disabled={page === totalPages}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AllUsers;
